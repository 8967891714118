// CSS
import styles from './Home.module.css';
import logo from './img/cfu_logo.png';
import homeMobile from './img/home-mobile.jpg';

// hooks

import { useNavigate, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuthentication } from '../../hooks/useAuthentication';
import { LogoComplete } from './img/cfu-logo-complete';

// components

const Home = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login, error: authError, loading } = useAuthentication();

  const [passwordType, setPasswordType] = useState('password');
  const [eye, setEye] = useState(false);
  const [type, setType] = useState(false);

  const Eye = () => {
    if (passwordType == 'password') {
      setPasswordType('text');
      setEye(true);
      setType(true);
    } else {
      setPasswordType('password');
      setEye(false);
      setType(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const user = {
      email,
      password,
    };

    const res = await login(user);
  };
  useEffect(() => {
    document.body.style.backgroundColor = '#0055bb';
    if (authError) {
      setError(authError);
    }
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [authError]);

  return (
    <>
      <div className="container-full bg-home">
        <div className="row g-0 g-0 pb-lg-3">
          <div className="col-lg-5 bg-yellow-light login-wrap mt-lg-5 pb-3">
            <div
              className={`${styles.logo} d-none d-lg-inline-flex justify-content-center align-items-center`}
            >
              <LogoComplete />
            </div>
            <img src={homeMobile} className="w-100 d-lg-none d-block"></img>
            <div className={styles.login}>
              <div className="row g-0">
                <div className="col-lg-8 offset-lg-4 d-flex justify-content-center">
                  <h4 className="px-3 mt-n3 pt-3 ps-lg-4 ms-lg-2 text-center text-lg-start">
                    Relying on technology and education for the human
                    development of Ukraine
                  </h4>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="row g-0">
                  {/* <p className=" pt-2 mb-1">
                    <strong>Log in for access our content:</strong>
                  </p> */}
                  <div className="row g-0">
                    <div className="col-lg-7">
                      <div className="mb-3 pt-3">
                        <label className="form-label text-black">
                          <small>Email address</small>
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          required
                          placeholder="Your e-mail"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div id="emailHelp" className="form-text"></div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-7 position-relative">
                      <div className="mb-3">
                        <label className="form-label text-black">
                          <small>Password</small>
                        </label>
                        <input
                          className="form-control ps-3"
                          type={passwordType}
                          name="password"
                          required
                          placeholder="Insert password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />

                        <i
                          onClick={Eye}
                          className={`fa ${eye ? 'fa-eye-slash' : 'fa-eye'}`}
                          style={{ top: 43, right: 10 }}
                        ></i>
                      </div>
                    </div>
                    <div className="col-4 d-flex align-items-end mb-3 ms-2">
                      {!loading && (
                        <button className="btn btn-primary float-end">
                          Login
                        </button>
                      )}
                      {loading && (
                        <button className="btn" disabled>
                          Wait...
                        </button>
                      )}
                      {error && <p className="error">{error}</p>}
                    </div>
                  </div>
                </div>
              </form>
              <div className="row g-0">
                <div className="col ms-1 ps-5">
                  Not a member yet? <Link to={`/register`}>Sign up</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-6 cards-home"></div>
        </div>
      </div>
      <div className="container-full bg-white ">
        <div
          className="row g-0 d-flex justify-content-center"
          style={{ maxHeight: '392px' }}
        >
          <div className="col-lg-5 offset-lg-1 py-2">
            <div className="py-4 px-3 px-lg-0">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/t19_w7j3OcY"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row d-flex justify-content-center">
          <div className="col-10">
            <h1 className="text-warning text-center">
              Give a try to start your programming career!{' '}
            </h1>{' '}
            <h5 className="mt-4 lh-base text-white text-center">
              Start your <strong>career as a developer</strong> by learning in a
              deep and meaningful way the basic concepts that are the foundation
              for any programming language.
            </h5>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
            <div className="col-md-3">
              <div className="card h-100 bg-white pt-3">
                <div className="d-flex p-3">
                  <strong className="col-7 d-flex align-items-end">
                    <h5 className="text-blue fs-5 lh-1">
                      Intermediate Javascript
                    </h5>
                  </strong>
                  <div className="col-5 p-0 m-0 d-flex align-items-start justify-content-end">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/icon-js-intermediateStroke.svg?alt=media&token=fd7f9fc5-efcc-432a-8308-4a9d6deb3c33"
                      alt="Course Icon"
                    />
                  </div>
                </div>
                <div className="card-body pt-0">
                  <ul className="Home_boxCourse__gTX2H pt-2 ps-1">
                    <li
                      style={{
                        backgroundSize: '24px',
                        backgroundPositionY: '5px',
                      }}
                    >
                      Classes, Asynchronous Programming, Promises, Async/Await
                    </li>
                  </ul>

                  <p className="HomeCourse_textGray__FpnqP lh-sm pb-2 ps-1">
                    Take your JavaScript to the next level by learning more
                    complex web apps using concepts such as classes, promises,
                    async/await, and requests.
                  </p>
                  <div
                    className="row ps-4 align-items-baseline position-absolute"
                    style={{ bottom: '65px' }}
                  >
                    <div className="col-2 p-0 m-0 d-flex align-items-start align-items-baseline">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/icon-js-level.svg?alt=media&amp;token=f8aa03d9-6a26-402f-9e2b-c9017ecc30bb"
                        style={{ width: '20px' }}
                      />
                    </div>
                    <div className="col-6 p-0 m-0 d-flex align-items-end">
                      <small className="text-blue ms-2">Intermediate</small>
                    </div>
                  </div>
                </div>
                <div className="card-footer justify-content-end d-flex ">
                  <a className="btn btn-primary w-100 px-4" href="/register">
                    Join this course!
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card h-100 bg-white pt-3">
                <div className="d-flex p-3">
                  <strong className="col-7 d-flex align-items-center">
                    <h5 className="text-blue fs-5 lh-1">
                      Introducing Programming with JavaScript
                    </h5>
                  </strong>
                  <div className="col-5 p-0 m-0 d-flex align-items-start justify-content-end">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/7/78/Programming_icon_flat_circle.svg"
                      alt="Course Icon"
                      style={{ width: '90px' }}
                    />
                  </div>
                </div>
                <div className="card-body pt-0">
                  <ul className="Home_boxCourse__gTX2H  ps-1">
                    <li
                      style={{
                        backgroundSize: '24px',
                        backgroundPositionY: '5px',
                      }}
                    >
                      Problem solving, Variables, Arrays, Loops, Conditionals,
                      Objects, Functions
                    </li>
                  </ul>
                  <p className="HomeCourse_textGray__FpnqP lh-sm ps-1 mb-5">
                    Start your career as a developer by learning in a deep and
                    meaningful way the basic concepts that are the foundation
                    for any programming language.
                  </p>
                  <div
                    className="row ps-4 align-items-baseline position-absolute"
                    style={{ bottom: '65px' }}
                  >
                    <div className="col-2 p-0 m-0 d-flex align-items-start align-items-baseline">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/icon-js-level.svg?alt=media&amp;token=f8aa03d9-6a26-402f-9e2b-c9017ecc30bb"
                        style={{ width: '18px' }}
                      />
                    </div>
                    <div className="col-6 p-0 m-0 d-flex align-items-end">
                      <small className="text-blue ms-2 text-nowrap">
                        Beginner Friendly
                      </small>
                    </div>
                  </div>
                </div>
                <div className="card-footer justify-content-end d-flex ">
                  <a className="btn btn-primary w-100 px-4" href="/register">
                    Join this course!
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card h-100 bg-white pt-3">
                <div className="d-flex p-3">
                  <strong className="col-7 d-flex align-items-center">
                    <h5 className="text-blue fs-5 lh-1">Introducing to HTML</h5>
                  </strong>
                  <div className="col-5 p-0 m-0 d-flex align-items-start justify-content-end">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/html_icon.png?alt=media&token=3620e63a-8d21-426f-a1b5-aec88ad4d6e3"
                      className="img-fluid"
                      alt="Course Icon"
                    />
                  </div>
                </div>
                <div className="card-body pt-0">
                  <ul className="Home_boxCourse__gTX2H  ps-1">
                    <li
                      style={{
                        backgroundSize: '24px',
                        backgroundPositionY: '5px',
                      }}
                    >
                      HTML structures, Headings, Paragraphs, Lists, Links,
                      Images
                    </li>
                  </ul>
                  <p className="HomeCourse_textGray__FpnqP lh-sm ps-1 mb-5">
                    In this Introduction to HTML course, you’ll take your first
                    exciting steps into the world of web development, learning
                    how to create the very foundation of every website out
                    there.
                  </p>
                  <div
                    className="row ps-4 align-items-baseline position-absolute"
                    style={{ bottom: '65px' }}
                  >
                    <div className="col-2 p-0 m-0 d-flex align-items-start align-items-baseline">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/icon-js-level.svg?alt=media&amp;token=f8aa03d9-6a26-402f-9e2b-c9017ecc30bb"
                        style={{ width: '18px' }}
                      />
                    </div>
                    <div className="col-6 p-0 m-0 d-flex align-items-end">
                      <small className="text-blue ms-2 text-nowrap">
                        Beginner Friendly
                      </small>
                    </div>
                  </div>
                </div>
                <div className="card-footer justify-content-end d-flex ">
                  <a className="btn btn-primary w-100 px-4" href="/register">
                    Join this course!
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card h-100 bg-white pt-3">
                <div className="d-flex p-3">
                  <strong className="col-7 d-flex align-items-center">
                    <h5 className="text-blue fs-5 lh-1">
                      Problem Solving for EveryDay Life
                    </h5>
                  </strong>
                  <div className="col-5 p-0 m-0 d-flex align-items-start justify-content-end">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/problem-solving-icon.png?alt=media&token=18957428-ee1f-4eda-8c0b-26d3c0da3212"
                      className="img-fluid"
                      alt="Course Icon"
                    />
                  </div>
                </div>
                <div className="card-body pt-0">
                  <ul className="Home_boxCourse__gTX2H  ps-1">
                    <li
                      style={{
                        backgroundSize: '24px',
                        backgroundPositionY: '5px',
                      }}
                    >
                      Learn to Identify, analyze, explore and select the best
                      solution
                    </li>
                  </ul>
                  <p className="ListCourses_textGray__QIKa4 lh-sm mt-lg-4 mt-3 pb-3 ps-1">
                    In this course, you will learn a problem-solving method to
                    tackle challenges in daily life. Transform your approach to
                    problems and make the best decisions when faced with
                    challenges.
                  </p>

                  <div
                    className="row ps-4 align-items-baseline position-absolute"
                    style={{ bottom: '65px' }}
                  >
                    <div className="col-2 p-0 m-0 d-flex align-items-start align-items-baseline">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/icon-js-level.svg?alt=media&amp;token=f8aa03d9-6a26-402f-9e2b-c9017ecc30bb"
                        style={{ width: '18px' }}
                      />
                    </div>
                    <div className="col-6 p-0 m-0 d-flex align-items-end">
                      <small className="text-blue ms-2 text-nowrap">
                        Beginner Friendly
                      </small>
                    </div>
                  </div>
                </div>
                <div className="card-footer justify-content-end d-flex ">
                  <a className="btn btn-primary w-100 px-4" href="/open">
                    Join this course!
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-4">
              <div className="card h-100 bg-white pt-3">
                <div className="d-flex p-3">
                  <strong className="col-md-3 col-7 d-flex align-items-center">
                    <h5 className="text-blue fs-5 lh-1">
                      Interactive Websites with JS, HTML and CSS
                    </h5>
                  </strong>
                  <div className="col-md-5 col-10 p-0 m-0 d-flex align-items-start">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/icon-js-interactve.svg?alt=media&token=55d9c493-f3e6-4c6a-ba34-ac494a701cfd"
                      alt="Course Icon"
                      style={{ width: '90px' }}
                    />
                  </div>
                </div>
                <div className="card-body pt-0">
                  <ul className="Home_boxCourse__gTX2H pt-2 ps-0 pb-2 mb-2">
                    <li
                      style={{
                        backgroundSize: '24px',
                        backgroundPositionY: '2px',
                      }}
                    >
                      Web development with HTML, CSS and JavaScript, Including a
                      Real-World Project
                    </li>
                  </ul>

                  <p className="HomeCourse_textGray__FpnqP lh-sm pb-3 ps-1">
                    Join this course and embark on a journey where you will
                    learn to develop blending HTML, CSS, and JavaScript to craft
                    captivating and interactive websites.
                  </p>
                  <div
                    className="row ps-4 align-items-baseline position-absolute"
                    style={{ bottom: '65px' }}
                  >
                    <div className="col-2 p-0 m-0 d-flex align-items-start align-items-baseline">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/icon-js-level.svg?alt=media&amp;token=f8aa03d9-6a26-402f-9e2b-c9017ecc30bb"
                        style={{ width: '20px' }}
                      />
                    </div>
                    <div className="col-6 p-0 m-0 d-flex align-items-end">
                      <small className="text-blue ms-2">Intermediate</small>
                    </div>
                  </div>
                </div>
                <div className="card-footer justify-content-start d-flex ">
                  <h5 className="text-primary">COMING SOON</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
