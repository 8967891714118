import React from 'react';
import styles from './Footer.module.css';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  if (
    !location.pathname.includes('lesson') &&
    !location.pathname.includes('independence-ukraine')
  ) {
    return (
      <footer
        className={`footer container-full px-lg-5 bgBlue ${
          location.pathname === '/' ? styles.homeFooter : ''
        } ${styles.footer}`}
      >
        <div className="py-4 mt-lg-4 container">
          <div className="row">
            <div className="col-6 d-flex justify-content-start">
              <small className="text-white">
                &copy; 2024 Coding for Ukraine
              </small>
            </div>
            <div className="col-6 d-flex justify-content-end align-items-end">
              <a
                href="https://www.threads.net/@codingforukraine"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={styles.iconSocialMedia}
                  src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/threads-white-icon.svg?alt=media&token=5b0b52bb-3948-4206-a409-4aebc9377b2c"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCVLYePMVyyKNWwALdRSG81g"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={styles.iconSocialMedia}
                  src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/youtube.svg?alt=media&token=14d2c330-37da-498b-a740-77f216befb6e&_gl=1*u8gung*_ga*MjAxNzU0MTM0LjE2ODUzODUyNTQ.*_ga_CW55HF8NVT*MTY4NTk3OTkyNS45LjEuMTY4NTk7OTk2Mi4wLjAuMA.."
                />
              </a>
              <a
                href="https://bsky.app/profile/codingforukraine.bsky.social"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={styles.iconSocialMedia}
                  src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/bluesky.png?alt=media&token=cef47602-deda-4454-9845-15db8da02c89"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }

  return null;
};

export default Footer;
